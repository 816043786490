import React from "react"

const Footer = props => (
  <footer id="footer">
    <ul className="icons">
      <li>
        <a
          href="https://twitter.com/CanadaLct"
          className="icon brands alt fa-twitter"
        >
          <span className="label">Twitter</span>
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/Live-Cell-Technologies-Canada-102096548202565/"
          className="icon brands alt fa-facebook-f"
        >
          <span className="label">Facebook</span>
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/company/lct-canada/"
          className="icon brands alt fa-linkedin-in"
        >
          <span className="label">LinkedIn</span>
        </a>
      </li>
      <li>
        <a
          href="mailto:admin@livecelltech.ca"
          className="icon solid alt fa-envelope"
        >
          <span className="label">Email</span>
        </a>
      </li>
    </ul>
    <ul className="copyright">
      <li>&copy; Live Cell Technologies Canada. All rights reserved.</li>
      <li>
        Design adapted from: <a href="http://html5up.net">HTML5 UP</a>
      </li>
      <li>
        Images: <a href="https://unsplash.com">Unsplash</a> and Original Art
      </li>
    </ul>
  </footer>
)

export default Footer
